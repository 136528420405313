/**
 * Loader
 */

class Loader {
  constructor() {
    this.readyState = "";
  }

  async load() {}
}

export default Loader;
